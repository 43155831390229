<template>
    <div class="education-wrapper">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item><span style="font-weight: 600; color: #303133 !important;">运营推广管理</span></el-breadcrumb-item>
            <el-breadcrumb-item>热门关键词</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="education-header">
            <IndustrySelection @selectListChange="selectListChange" :selectList="selectList" text="行业分类："></IndustrySelection>
            <div>
                <el-button class="btn-blue" @click="sendBtn" style="display: inline-block;">发送</el-button>
                <el-button class="btn-blue" @click="addBtn" style="display: inline-block;">创建热门关键词</el-button>
            </div>
        </div>
        <el-table ref="multipleTable" :data="keywordList" border style="width: 100%; margin-top: 20px; flex: 1" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F2F3FA'}"
                  :cell-style="{fontSize: '14px',color: '#343441'}">
            <el-table-column type="selection" width="50" align="center"></el-table-column>
            <el-table-column prop="name" align="center" label="关键词名称"></el-table-column>
            <el-table-column prop="remark" align="center" label="备注"></el-table-column>
            <el-table-column prop="category_name" align="center" label="行业分类"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link :underline="false" class="link-blue" @click="copyBtn(scope.row.id)">复制</el-link>
                    <el-link :underline="false" class="link-blue" @click="editBtn(scope.row)">编辑</el-link>
                    <el-link :underline="false" type="danger" @click="delBtn(scope.row.id)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin-top: 10px;"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       :total="listPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="pagesCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import { copyHotKeywordsAPI, sendHotKeywordsAPI } from "../../../utils/apis"
    import IndustrySelection from "../../admin/IndustrySelection";

    export default {
        name: "HotKeywords",
        components: {
            IndustrySelection
        },
        data() {
            return {
                //热门关键词列表
                keywordList: [],
                //分页
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                selectList: [],
                selectVal: void 0,
            }
        },
        mounted() {
            this.getKeywordList();
            this.getCategoryConfig();
        },
        methods: {
            async sendBtn(){
                let id =this.$refs.multipleTable.selection.map((item) => item.id);
                if(id && id.length > 0){
                  const result = await this.$common.sendData(sendHotKeywordsAPI, { ids: id });
                  result && this.getKeywordList(this.selectVal);
                }else{
                  this.$message.error("请勾选需要发送的数据！")
                }
            },
            selectListChange(id) {
                this.selectVal = id;
                this.getKeywordList(id)
            },
            // 获取行业分类
            getCategoryConfig() {
                this.$http.axiosGetBy(this.$api.categoryList, {}, (res) => {
                    if (res.code === 200) {
                        this.selectList = res.data;
                    } else {
                        this.$message.warning(res.msg);
                    }
                });
            },
            // 获取热门关键词列表
            getKeywordList(id) {
                let params = {
                    type: 'page',
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum,
                    category_id: id ? id : ''
                }
                this.$http.axiosGetBy(this.$api.hotKeywordList, params, (res) => {
                    if (res.code === 200) {
                        this.keywordList = res.data.data;
                        this.listPages.total = res.data.total;
                        if (this.listPages.total !== 0 && this.keywordList.length === 0) {
                            this.listPages.currentPageNum--;
                            this.getKeywordList(this.selectVal);
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 切换分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val;
                this.getKeywordList(this.selectVal);
            },
            // 添加
            addBtn() {
                this.$router.push('/admin/operatedata/hotkeywords/create')
            },
            //编辑
            editBtn(row) {
                this.$router.push({
                    path: '/admin/operatedata/hotkeywords/create',
                    query: {
                        id: row.id
                    }
                })
            },
            // 删除
            delBtn(id) {
                this.$confirm('是否删除该关键词，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                }).then(() => {
                    this.$http.axiosGetBy(this.$api.delHotKeyword, {id: id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg)
                            this.getKeywordList(this.selectVal);
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
            async copyBtn(id) {
                    const valid = await this.$common.copyTemplatePack(copyHotKeywordsAPI, { id });
                    valid && this.getKeywordList(this.selectVal);
            }
        }
    }
</script>

<style scoped lang="scss">
    .education-wrapper {
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .education-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 24px;
            .el-breadcrumb {
                line-height: 40px;
            }
        }
    }
    .el-link + .el-link {
        margin-left: 10px;
    }
</style>